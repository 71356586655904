import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fd77cd1a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _4161c7d8 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _348e0fab = () => interopDefault(import('../pages/afrekenen.vue' /* webpackChunkName: "pages/afrekenen" */))
const _18128293 = () => interopDefault(import('../pages/betaling/index.vue' /* webpackChunkName: "pages/betaling/index" */))
const _bb6a8bcc = () => interopDefault(import('../pages/brochure-aanvragen.vue' /* webpackChunkName: "pages/brochure-aanvragen" */))
const _545483e9 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _ab3560ca = () => interopDefault(import('../pages/inloggen.vue' /* webpackChunkName: "pages/inloggen" */))
const _684fbf2d = () => interopDefault(import('../pages/kennisbank/index.vue' /* webpackChunkName: "pages/kennisbank/index" */))
const _0307f41e = () => interopDefault(import('../pages/nieuws.vue' /* webpackChunkName: "pages/nieuws" */))
const _dda2d898 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _e94fbbfc = () => interopDefault(import('../pages/nieuws/_category/index.vue' /* webpackChunkName: "pages/nieuws/_category/index" */))
const _51454225 = () => interopDefault(import('../pages/nieuws/_category/_item/index.vue' /* webpackChunkName: "pages/nieuws/_category/_item/index" */))
const _0d6b28fe = () => interopDefault(import('../pages/over.vue' /* webpackChunkName: "pages/over" */))
const _ce1c508e = () => interopDefault(import('../pages/over/_slug.vue' /* webpackChunkName: "pages/over/_slug" */))
const _876084b6 = () => interopDefault(import('../pages/over-ons.vue' /* webpackChunkName: "pages/over-ons" */))
const _816effbe = () => interopDefault(import('../pages/producten/index.vue' /* webpackChunkName: "pages/producten/index" */))
const _90ebccd0 = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _c6c6d9be = () => interopDefault(import('../pages/school/index.vue' /* webpackChunkName: "pages/school/index" */))
const _4d6633c0 = () => interopDefault(import('../pages/shop.vue' /* webpackChunkName: "pages/shop" */))
const _3a1c54a3 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _3798c431 = () => interopDefault(import('../pages/shop/_category/index.vue' /* webpackChunkName: "pages/shop/_category/index" */))
const _4bd20321 = () => interopDefault(import('../pages/shop/_category/_product.vue' /* webpackChunkName: "pages/shop/_category/_product" */))
const _ae8777bc = () => interopDefault(import('../pages/speciaal-voor-scholen.vue' /* webpackChunkName: "pages/speciaal-voor-scholen" */))
const _50cb5956 = () => interopDefault(import('../pages/sso.vue' /* webpackChunkName: "pages/sso" */))
const _3e188a6e = () => interopDefault(import('../pages/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/index" */))
const _633b3054 = () => interopDefault(import('../pages/winkelwagen.vue' /* webpackChunkName: "pages/winkelwagen" */))
const _6f61643e = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _231120aa = () => interopDefault(import('../pages/admin/abonnementen/index.vue' /* webpackChunkName: "pages/admin/abonnementen/index" */))
const _550fbf5f = () => interopDefault(import('../pages/admin/facturen/index.vue' /* webpackChunkName: "pages/admin/facturen/index" */))
const _52409633 = () => interopDefault(import('../pages/admin/offertes/index.vue' /* webpackChunkName: "pages/admin/offertes/index" */))
const _788cfb23 = () => interopDefault(import('../pages/admin/pakketten.vue' /* webpackChunkName: "pages/admin/pakketten" */))
const _95379710 = () => interopDefault(import('../pages/admin/relaties/index.vue' /* webpackChunkName: "pages/admin/relaties/index" */))
const _3ab315c5 = () => interopDefault(import('../pages/betaling/afgerond.vue' /* webpackChunkName: "pages/betaling/afgerond" */))
const _533734c7 = () => interopDefault(import('../pages/demo/rekenblobs.vue' /* webpackChunkName: "pages/demo/rekenblobs" */))
const _f6046cc8 = () => interopDefault(import('../pages/demo/taalblobs.vue' /* webpackChunkName: "pages/demo/taalblobs" */))
const _3c543eb0 = () => interopDefault(import('../pages/info/algemene-voorwaarden.vue' /* webpackChunkName: "pages/info/algemene-voorwaarden" */))
const _a330311a = () => interopDefault(import('../pages/info/privacy-statement.vue' /* webpackChunkName: "pages/info/privacy-statement" */))
const _a8dcdd46 = () => interopDefault(import('../pages/school/leerling.vue' /* webpackChunkName: "pages/school/leerling" */))
const _3b8b8d35 = () => interopDefault(import('../pages/admin/facturen/aanmaken.vue' /* webpackChunkName: "pages/admin/facturen/aanmaken" */))
const _520355e1 = () => interopDefault(import('../pages/admin/offertes/aanmaken.vue' /* webpackChunkName: "pages/admin/offertes/aanmaken" */))
const _3ff2557c = () => interopDefault(import('../pages/admin/relaties/aanmaken.vue' /* webpackChunkName: "pages/admin/relaties/aanmaken" */))
const _ac393e5a = () => interopDefault(import('../pages/admin/abonnementen/_id.vue' /* webpackChunkName: "pages/admin/abonnementen/_id" */))
const _2554895b = () => interopDefault(import('../pages/admin/offertes/_id.vue' /* webpackChunkName: "pages/admin/offertes/_id" */))
const _cf16f684 = () => interopDefault(import('../pages/admin/organisaties/_id.vue' /* webpackChunkName: "pages/admin/organisaties/_id" */))
const _286052e8 = () => interopDefault(import('../pages/admin/particulieren/_id.vue' /* webpackChunkName: "pages/admin/particulieren/_id" */))
const _56b2188f = () => interopDefault(import('../pages/admin/scholen/_id.vue' /* webpackChunkName: "pages/admin/scholen/_id" */))
const _e1245856 = () => interopDefault(import('../pages/admin/studenten/_id.vue' /* webpackChunkName: "pages/admin/studenten/_id" */))
const _0a07aace = () => interopDefault(import('../pages/wachtwoord-vergeten/reset/_token.vue' /* webpackChunkName: "pages/wachtwoord-vergeten/reset/_token" */))
const _529de2a1 = () => interopDefault(import('../pages/contact/_slug.vue' /* webpackChunkName: "pages/contact/_slug" */))
const _66991de5 = () => interopDefault(import('../pages/kennisbank/_slug.vue' /* webpackChunkName: "pages/kennisbank/_slug" */))
const _46055fc8 = () => interopDefault(import('../pages/kennisbank/_slug/_item.vue' /* webpackChunkName: "pages/kennisbank/_slug/_item" */))
const _84dc424e = () => interopDefault(import('../pages/producten/_slug.vue' /* webpackChunkName: "pages/producten/_slug" */))
const _68336286 = () => interopDefault(import('../pages/verleng/_id.vue' /* webpackChunkName: "pages/verleng/_id" */))
const _43f66950 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _bf9a5dd0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0f4a7920 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _fd77cd1a,
    name: "account"
  }, {
    path: "/admin",
    component: _4161c7d8,
    name: "admin"
  }, {
    path: "/afrekenen",
    component: _348e0fab,
    name: "afrekenen"
  }, {
    path: "/betaling",
    component: _18128293,
    name: "betaling"
  }, {
    path: "/brochure-aanvragen",
    component: _bb6a8bcc,
    name: "brochure-aanvragen"
  }, {
    path: "/contact",
    component: _545483e9,
    name: "contact"
  }, {
    path: "/inloggen",
    component: _ab3560ca,
    name: "inloggen"
  }, {
    path: "/kennisbank",
    component: _684fbf2d,
    name: "kennisbank"
  }, {
    path: "/nieuws",
    component: _0307f41e,
    children: [{
      path: "",
      component: _dda2d898,
      name: "nieuws"
    }, {
      path: ":category",
      component: _e94fbbfc,
      name: "nieuws-category"
    }, {
      path: ":category/:item",
      component: _51454225,
      name: "nieuws-category-item"
    }]
  }, {
    path: "/over",
    component: _0d6b28fe,
    name: "over",
    children: [{
      path: ":slug?",
      component: _ce1c508e,
      name: "over-slug"
    }]
  }, {
    path: "/over-ons",
    component: _876084b6,
    name: "over-ons"
  }, {
    path: "/producten",
    component: _816effbe,
    name: "producten"
  }, {
    path: "/registreren",
    component: _90ebccd0,
    name: "registreren"
  }, {
    path: "/school",
    component: _c6c6d9be,
    name: "school"
  }, {
    path: "/shop",
    component: _4d6633c0,
    children: [{
      path: "",
      component: _3a1c54a3,
      name: "shop"
    }, {
      path: ":category",
      component: _3798c431,
      name: "shop-category"
    }, {
      path: ":category/:product",
      component: _4bd20321,
      name: "shop-category-product"
    }]
  }, {
    path: "/speciaal-voor-scholen",
    component: _ae8777bc,
    name: "speciaal-voor-scholen"
  }, {
    path: "/sso",
    component: _50cb5956,
    name: "sso"
  }, {
    path: "/wachtwoord-vergeten",
    component: _3e188a6e,
    name: "wachtwoord-vergeten"
  }, {
    path: "/winkelwagen",
    component: _633b3054,
    name: "winkelwagen"
  }, {
    path: "/zoekresultaten",
    component: _6f61643e,
    name: "zoekresultaten"
  }, {
    path: "/admin/abonnementen",
    component: _231120aa,
    name: "admin-abonnementen"
  }, {
    path: "/admin/facturen",
    component: _550fbf5f,
    name: "admin-facturen"
  }, {
    path: "/admin/offertes",
    component: _52409633,
    name: "admin-offertes"
  }, {
    path: "/admin/pakketten",
    component: _788cfb23,
    name: "admin-pakketten"
  }, {
    path: "/admin/relaties",
    component: _95379710,
    name: "admin-relaties"
  }, {
    path: "/betaling/afgerond",
    component: _3ab315c5,
    name: "betaling-afgerond"
  }, {
    path: "/demo/rekenblobs",
    component: _533734c7,
    name: "demo-rekenblobs"
  }, {
    path: "/demo/taalblobs",
    component: _f6046cc8,
    name: "demo-taalblobs"
  }, {
    path: "/info/algemene-voorwaarden",
    component: _3c543eb0,
    name: "info-algemene-voorwaarden"
  }, {
    path: "/info/privacy-statement",
    component: _a330311a,
    name: "info-privacy-statement"
  }, {
    path: "/school/leerling",
    component: _a8dcdd46,
    name: "school-leerling"
  }, {
    path: "/admin/facturen/aanmaken",
    component: _3b8b8d35,
    name: "admin-facturen-aanmaken"
  }, {
    path: "/admin/offertes/aanmaken",
    component: _520355e1,
    name: "admin-offertes-aanmaken"
  }, {
    path: "/admin/relaties/aanmaken",
    component: _3ff2557c,
    name: "admin-relaties-aanmaken"
  }, {
    path: "/admin/abonnementen/:id",
    component: _ac393e5a,
    name: "admin-abonnementen-id"
  }, {
    path: "/admin/offertes/:id",
    component: _2554895b,
    name: "admin-offertes-id"
  }, {
    path: "/admin/organisaties/:id?",
    component: _cf16f684,
    name: "admin-organisaties-id"
  }, {
    path: "/admin/particulieren/:id?",
    component: _286052e8,
    name: "admin-particulieren-id"
  }, {
    path: "/admin/scholen/:id?",
    component: _56b2188f,
    name: "admin-scholen-id"
  }, {
    path: "/admin/studenten/:id?",
    component: _e1245856,
    name: "admin-studenten-id"
  }, {
    path: "/wachtwoord-vergeten/reset/:token",
    component: _0a07aace,
    name: "wachtwoord-vergeten-reset-token"
  }, {
    path: "/contact/:slug",
    component: _529de2a1,
    name: "contact-slug"
  }, {
    path: "/kennisbank/:slug",
    component: _66991de5,
    name: "kennisbank-slug",
    children: [{
      path: ":item?",
      component: _46055fc8,
      name: "kennisbank-slug-item"
    }]
  }, {
    path: "/producten/:slug",
    component: _84dc424e,
    name: "producten-slug"
  }, {
    path: "/verleng/:id?",
    component: _68336286,
    name: "verleng-id"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _43f66950,
    name: "wachtwoord-instellen-token"
  }, {
    path: "/",
    component: _bf9a5dd0,
    name: "index"
  }, {
    path: "/*",
    component: _0f4a7920,
    name: "*"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decodeURIComponent(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
